import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { useEffect } from "react"

const Cookies = () => {

  useEffect(() => {

    const target = document.getElementById("cookie-dec-target")
    if ( target ) {
      const script = document.createElement("script");
      script.src = "https://consent.cookiebot.com/a9bc9a5f-60a5-4fe0-b305-79de1066b7c4/cd.js";
      script.async = true;
      target.appendChild(script)
    }

  }, [])

    return (
      <Layout>

        <Seo/>

        <section className="landing mod-dark-blue mod-basic">
          <div className="fw">
            <div className="landing-intro">
              <h1 className="mod-white">Cookie Policy</h1>
            </div>
          </div>
        </section>

        <section>
          <div className="fw">
            <div id="cookie-dec-target"/>
          </div>
        </section>

      </Layout>
    )
}

export default Cookies